<template>
  <r-page-modal>
    <template #page-header>
      <h2 class="page-title">Create Metode</h2>
    </template>
    <template #action-bar>
      <b-button
        class="is-primary"
        outlined
        tag="router-link"
        to="/master-data/limit/metode/"
      >
        Cancel
      </b-button>
      <b-button class="is-primary" @click="confirm()">Create</b-button>
      <b-button class="is-primary" @click="warning()">Warning</b-button>
    </template>
    <template #page-content>
      <r-card>
        <div class="columns">
          <div class="column is-12">
            <validation-provider
              name="Name"
              rules="required"
              v-slot="{ errors }"
            >
              <b-field
                label="Name*"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-input
                  v-model="form.name"
                  placeholder="e.g: John Doe"
                ></b-input>
              </b-field>
            </validation-provider>
            <validation-provider
              name="Email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <b-field
                label="Email*"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-input
                  v-model="form.email"
                  placeholder="e.g: johndoe@example.com"
                ></b-input>
              </b-field>
            </validation-provider>
            <validation-provider
              name="Email"
              rules="required"
              v-slot="{ errors }"
            >
              <b-field
                label="Message*"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-input type="textarea" v-model="form.message"></b-input>
              </b-field>
            </validation-provider>
          </div>
        </div>
      </r-card>
    </template>
  </r-page-modal>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: '',
      },
      lipsum: `Lorem ipsum dolor sit amet consectetur adipisicing elit. 
              Laboriosam voluptatibus magni et? Placeat tempore illum 
              praesentium veritatis dolorum totam ipsa iste amet, 
              quasi dolores sed doloribus deleniti delectus rerum quia?`,
      confirmText: `If you processed, you will lose all your Personal Clients. 
                    Are you sure you want to delete it?`,
    }
  },
  methods: {
    confirm() {
      this.$swal({
        showCloseButton: true,
        showConfirmButton: false,
        titleText: 'Information',
        customClass: {
          confirmButton: 'button is-primary',
        },
        text: this.lipsum,
      })
    },
    warning() {
      this.$swal({
        icon: 'warning',
        showCancelButton: true,
        // showDenyButton: true,
        confirmButtonText: 'Continue',
        denyButtonText: 'No',
        input: 'checkbox',
        inputValue: 1,
        inputValidator: (result) => {
          return !result && 'You need to agree with T&C'
        },
        inputPlaceholder: 'I agree with the terms and conditions',
        customClass: {
          confirmButton: 'button is-error',
          denyButton: 'button is-error',
          cancelButton: 'button',
        },
        text: this.confirmText,
      }).then((res) => {
        if (res && res.isConfirmed) {
          this.$swal({
            icon: 'success',
            titleText: 'Thank You',
            confirmButtonText: 'Done',
            customClass: {
              confirmButton: 'button is-success',
            },
          })
        } else {
          this.$swal({
            icon: 'error',
            titleText: 'Failed',
            confirmButtonText: 'Go Back Home',
            customClass: {
              confirmButton: 'button is-grey',
            },
          })
        }
      })
    },
  },
}
</script>
